



































import {
  defineComponent,
  ref,
  inject,
  computed,
  onMounted
} from '@nuxtjs/composition-api';
import { usePartFinderStore } from '../stores/partFinder';
import { storeToRefs } from 'pinia';
import Dropdowns from './Dropdowns.vue';
import { SfHeading, SfButton } from '@storefront-ui/vue';

interface DropdownOption {
  value: number;
  label: string;
}

export default defineComponent({
  name: 'PartFinderFilter',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    Dropdowns,
    SfHeading,
    SfButton
  },
  emits: ['selectFilter'],
  setup (props, { emit }) {
    const isOpen = ref(true);
    
    const store = usePartFinderStore();
    const {
      brands,
      models,
      finders,
      selectedBrand,
      selectedModel,
      selectedFinder
    } = storeToRefs(store)
    const {
      loadFinders,
      setBrand,
      setModel,
      setFinder
    } = store;

    const { selectedFilters } = inject('UseFiltersProvider');
    

    const isValid = computed(() => selectedBrand.value && selectedModel.value && selectedFinder.value);
    function applyFilter() {
      if (!isValid.value) return;
      emit('selectFilter', {
        brand: selectedBrand.value.slug,
        model: selectedModel.value.slug,
        finder: selectedFinder.value.slug
      });
    }

    async function initFromUrlParams() {
      let brandId = selectedFilters.value['pf_brand_id'];
      if (!brandId?.length) return;
      brandId = Array.isArray(brandId) ? brandId[0] : brandId;
      const brand = brands.value.find(b => b.brand_id === parseInt(brandId));
      if (!brand) return;
      await setBrand(brand);

      let modelId = selectedFilters.value['pf_model_id'];
      if (!modelId?.length) return;
      modelId = Array.isArray(modelId) ? modelId[0] : modelId;
      const model = models.value.find(m => m.model_id === parseInt(modelId));
      if (!model) return;
      await setModel(model);

      let finderId = selectedFilters.value['pf_finder_id'];
      if (!finderId?.length) return;
      finderId = Array.isArray(finderId) ? finderId[0] : finderId;
      const finder = finders.value.find(t => t.finder_id === parseInt(finderId));
      if (!finder) return;
      await setFinder(finder);
    }

    onMounted(async () => {
      if (!finders.value?.length) {
        await loadFinders();
      }
      models.value = [];
      brands.value = [];
      selectedFinder.value = null;
      selectedBrand.value = null;
      selectedModel.value = null;
      await initFromUrlParams();
    })

    return {
      isOpen,
      isValid,
      applyFilter
    }
  }
})
